@import "@repo/common/vars.scss";
.dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 150px;
  border: solid 1px transparent;
  border-radius: 3px;
}

.label {
  margin-bottom: 2px;
  font-size: 14px;
}

.select {
  :global {
    .ant-select-selector {
      height: standardSizes(48) !important;
    }
    input.ant-select-selection-search-input {
      padding-top: 12px !important;
      padding-bottom: 12px !important;
      height: standardSizes(48) !important;
    }
    .ant-select-selection-item {
      line-height: standardSizes(48) !important;
    }
    .ant-select-selection-item-content {
      line-height: 22px;
    }
    .ant-select-selection-placeholder {
      margin: auto;
    }
  }
}