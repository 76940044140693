@import "@repo/common/vars.scss";
.textInput.textInput {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 150px;
  border: solid 0px transparent;
  border-radius: 3px;
  
  input {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}