@import "@repo/common/vars.scss";
@import "material-icons/iconfont/material-icons.css";
@import 'material-symbols';
@import "overlayscrollbars/overlayscrollbars.css";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

.clickable {
  cursor: pointer;
}

button {
  border: none;
}

.slick-slider {
  position: relative;
}

.material-symbols-sharp {
  font-variation-settings: 'FILL' 1;
}