@import "@repo/common/vars.scss";

.sliderInput {
  min-width: 150px;
}

.label {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: standardSizes(14);
}