@import "@repo/common/vars.scss";
.label {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: standardSizes(14);
}

.radioGroupContainer.radioGroupContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 150px;
  border: solid 1px transparent;
  border-radius: 3px;
  display: inline-block;
}

.radioGroup.radioGroup {
  :global {
    border-radius: 12px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;

    .ant-radio-wrapper:not(.ant-radio-wrapper-disabled) {
      background-color: #f5f5f7;
      border-radius: 12px;
      border: 2px solid #f5f5f7;
      padding: 12px;

      &:ant-radio-wrapper-checked {
        border-color: #2c71f0;
      }
    }

    .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled) {
      background-color: #ebebeb;
      border-color: #ebebeb;
      border-width: 2px;

      &::before {
        display: none;
      }

      &:ant-radio-button-wrapper-checked {
        border-color: #000;
      }

      .ant-radio-button-checked+span {
        background-color: #000;
        color: #fff;
      }
    }

    .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) span {
      color: rgba(0, 0, 0, 0.4);
    }

    .ant-radio-button-checked {
      background-color: #000;
    }

    .ant-radio-inner::after {
      display: none;
    }
  }
}