@import "@repo/common/vars.scss";
.draggerInput {
  display: inline-block;
}

.label {
  margin-bottom: 8px;
  font-weight: 500;
}

.hideUpload.hideUpload {
  :global {
    .ant-upload-list {
      display: none;
    }

    .ant-upload-btn {
      padding: 0 !important;
      display: block !important;
    }

    .ant-upload-drag {
      border-radius: 16px !important;
      border: 2px solid #2c71f0 !important;
      padding: 24px 20px 24px 16px !important;
    }

    .ant-upload-drag-container {
      display: block !important;
    }
  }
}

.upload {
  display: inline-flex;
  flex-direction: column;
  width: 100%;

  :global {
    .ant-upload-list-item .ant-upload-list-item-action {
      width: auto;
    }

    .ant-upload.ant-upload-drag {
      background: #f0f6ff !important;
      border-radius: 16px;
      border: 1px dashed #2c71f0 !important;
    }
  }
}

.noRound {
  :global {
    .ant-upload.ant-upload-drag {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
}
