@import "@repo/common/vars.scss";
.error {
  
  :global {
    .ant-checkbox-inner::after {
      background-color: red;
    }
  }
}

.alignTop {
  :global(.ant-checkbox) {
    align-self: flex-start;
  }
}